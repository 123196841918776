import React from 'react';
import styled from 'styled-components';


// #region Styles
const Section = styled.section`
  position: relative;
  padding: 1px 30px;

  &:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    left: -50%;
    top: 0;
    z-index: -1;
    background: var(--gray-color);
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    margin: 1.6em auto 2em;
  }

  @media screen and (min-width: 1200px) {
    padding: 1em 15px 6em;
  }
`;

const SubSection = styled.div`
  img {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0.4em rgba(0,0,0,0.15);
  }

  h2 {
    text-align: center;
    font-family: 'todayshop-bold', sans-serif;
    letter-spacing: 0.5px;
    margin: 1em 0 0;
    color: var(--accent-color);
  }

  p {
    margin: 0 0 4em;
    color: var(--text-color);
  }

  @media screen and (min-width: 768px) {
    flex: 0 0 30.93%;
    max-width: 30.93%;

    &:last-child {
      flex: 0 0 38.14%;
      max-width: 38.14%;
    }
  }

  @media screen and (min-width: 992px) {
    .icon {
      &.merchandising,
      &.estampacion {
        margin-top: 3em;
      }
    }
  }
`;
// #endregion

export default class AboutUs extends React.Component {
  render() {
    return (
      <Section className="container">
        <h1>Huerticales</h1>
        <p>
          A continuación se describen 3 diseños. El tamaño depende del área de
          la que dispones para construir el sistema en tu hogar.
        </p>
        <div className="row align-items-start">
          <SubSection className="col-md-4">
            <img src="img/huertical-a@2x.jpg" alt="Huertical diseño A, capacidad para 28 plantas" />
            <h2>Huertical A:</h2>
            <p>
              Alto: 2m, Ancho: 1.25m - 1.5m <br />
              Capacidad para 28 plantas.
            </p>
          </SubSection>
          <SubSection className="col-md-4">
            <img src="img/huertical-b@2x.jpg" alt="Huertical diseño B, capacidad para 38 plantas" />
            <h2>Huertical B:</h2>
            <p>
              Alto: 2m, Ancho: 1.55m - 2.85m <br />
              Capacidad para 38 plantas.
            </p>
          </SubSection>
          <SubSection className="col-md-4">
            <img src="img/huertical-c@2x.jpg" alt="Huertical diseño C, capacidad para 58 plantas" />
            <h2>Huertical C:</h2>
            <p>
              Alto: 2m, Ancho: 1.9m - 2.2m <br />
              Capacidad para 58 plantas.
            </p>
          </SubSection>
        </div>
      </Section>
    );
  }
}

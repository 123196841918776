import React from 'react';
import styled from 'styled-components';
import Input from './input';
import {
  LINK_NOSOTROS,
  LINK_NUESTRAS_LINEAS,
  LINK_HUERTICALES,
  LINK_EXPERIENCIAS,
  LINK_CONTACTO,
} from './constants';


const StyledFooter = styled.section`
  position: relative;
  padding-top: 1px;
  padding-bottom: 1em;
  color: var(--footer-fg-color);

  --label-color: var(--base-color);
  --label-background: white;
  --label-margin-bottom: 0.2em;
  --label-font-size: 1em;

  img {
    display: block;
    width: 80%;
    margin: 3em auto;
  }

  &:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    left: -25%;
    top: 0;
    z-index: -1;
    background: var(--footer-bg-color);
  }

  h3 {
    margin-left: 2em;
    font-family: 'todayshop-bold', sans-serif;
  }

  a {
    color: var(--footer-fg-color);
  }

  .links {
    padding-bottom: 2em;
    a {
      margin-left: 2em;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin: 1em 0 2em;
  }

  .icon {
    margin: 3em auto 1.5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.facebook,
    &.instagram {
      margin: 1em;
      width: 32px;
      height: 32px;
      background-image: url(img/icon-facebook.svg);
    }

    &.instagram {
      background-image: url(img/icon-instagram.svg);
    }
  }

  .newsletter-input {
    position: relative;
    margin: 0 2em;

    --label-border-radius: 0.4em;

    input[type="submit"] {
      position: absolute;
      width: calc(var(--label-font-size) * 2.4);
      height: calc(var(--label-font-size) * 2.4);
      top: 0;
      right: 0;
      border: none;
      outline: none;
      color: white;
      border-radius: 0 0.4em 0.4em 0;
      background-color: var(--accent-color);
      background-image: url(img/icon-send.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 6px;

    }
  }

  .small {
    font-size: 0.7em;
  }

  @media screen and (min-width: 768px) {
    padding-top: 2em;

    img {
      height: 4em;
    }

    h3 {
      margin-top: 1.6em;
    }
  }
`;

export default class Footer extends React.Component {
  state = {
    email: '',
  };

  onInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email } = this.state;
    console.log(email);
  }

  render() {
    return (
      <StyledFooter className="container">
        <div className="row justify-content-center">
          <div className="col-md">
            <img src="img/logo-huertical-dark.svg" alt="Huertical - Cultivando en casa" />
          </div>
          <div className="d-none d-lg-block col-md links">
            <h3>LINKS</h3>
            <a href={LINK_NOSOTROS}>NOSOTROS</a><br />
            <a href={LINK_NUESTRAS_LINEAS}>NUESTRAS LÍNEAS</a><br />
            <a href={LINK_HUERTICALES}>HUERTICALES</a><br />
            <a href={LINK_EXPERIENCIAS}>EXPERIENCIAS</a><br />
            <a href={LINK_CONTACTO}>CONTACTO</a>
          </div>
          <div className="col-md">
            <h3>NEWSLETTER</h3>
            <div className="newsletter-input">
              <form onSubmit={this.onSubmit}>
                <Input
                  required
                  label="Email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  onInputChange={this.onInputChange}
                />
                <input type="submit" value="" />
              </form>
            </div>
            <div className="social-icons">
              <a href="http://facebook.com">
                <div className="icon facebook" />
              </a>
              <a href="http://instagram.com">
                <div className="icon instagram" />
              </a>
            </div>
          </div>
        </div>
        <p className="small">
          Copyright © 2018 Huertical. <br className="d-md-none" />
          Todos los derechos reservados.
        </p>
        <p className="small">
          Powered by: <a href="https://cuadroacuadro.co">www.cuadroacuadro.co</a>
        </p>
      </StyledFooter>
    );
  }
}

import React from 'react';
import { hydrate, render } from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { Element, scroller } from 'react-scroll';
import {
  LINK_HOME,
  LINK_NOSOTROS,
  LINK_NUESTRAS_LINEAS,
  LINK_HUERTICALES,
  LINK_EXPERIENCIAS,
  LINK_CONTACTO,
} from './constants';
import Header from './header';
import Banner from './banner';
import AboutUs from './aboutus';
import ProductLines from './productlines';
import Huerticales from './huerticales';
import Experiencias from './experiencias';
import ContactInfo from './contactinfo';
import ContactForm from './contactform';
import Footer from './footer';


// #region Global styles
const GlobalStyles = createGlobalStyle`
  :root {
    --base-color: #477943;
    --text-color: #202B30;
    --accent-color: #9dC44C;
    --accent-alt-color: #C23752;
    --gray-color: #F9F9F9;
    --yellow-color: #E6B544;
    --footer-bg-color: #202B30;
    --footer-fg-color: white;

    --contact-bar-height: 32px;
    --header-height-xs: 92px;
    --header-height-md: 80px;
    --header-height-xl: 100px;
  }

  #root {
    width: 100%;
    overflow: hidden;
  }

  * {
    font-family: 'todayshop', sans-serif;
    font-weight: normal;
  }

  h1 {
    position: relative;
    margin: 2em 0;
    font-size: 2.2em;
    font-family: 'todayshop-medium', sans-serif;
    text-align: center;
    letter-spacing: 1px;
    color: var(--text-color);

    &:before {
      content: "";
      position: absolute;
      width: 19px;
      height: 20px;
      top: -12px;
      left: 50%;
      margin-left: -10px;
      background-image: url(img/icon-h2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  p {
    text-align: center;
    color: #999999;
    font-size: 1.125em;
    line-height: 1.3;
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 2.8em;
    }

    h2 {
      font-size: 1.7em;
    }

    p {
      font-size: 1.2em;
    }
  }

  @media screen and (min-width: 1440px) {
    .d-xxl-block {
      display: block!important;
    }

    .d-xxl-none {
      display: none!important;
    }
  }
`;
// #endregion

class App extends React.Component {
  componentDidMount() {
    setTimeout(this.scrollTo, 600);
  }

  scrollTo = () => {
    const { hash } = window.location;
    if (hash.indexOf('#') === -1) return;
    scroller.scrollTo(hash.replace('#', ''), {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: document.getElementsByTagName('header')[0].offsetHeight * -1,
    });
  }

  render() {
    return (
      <div>
        <Header scrollTo={this.scrollTo} />
        <GlobalStyles />
        <Element name={LINK_HOME.replace('#', '')} />
        <Banner slidesData={[{
          alt: 'Cultivando en casa',
          srcXs: 'img/banner1-xs@2x.jpg',
          srcMd: 'img/banner1-md.jpg',
          srcXl: 'img/banner1-md@2x.jpg',
        }, {
          alt: 'Alimentos frescos y sanos',
          srcXs: 'img/banner2-xs@2x.jpg',
          srcMd: 'img/banner2-md.jpg',
          srcXl: 'img/banner2-md@2x.jpg',
        }, {
          alt: 'Mejor modo de vivir',
          srcXs: 'img/banner3-xs@2x.jpg',
          srcMd: 'img/banner3-md.jpg',
          srcXl: 'img/banner3-md@2x.jpg',
        }]}
        />
        <Element name={LINK_NOSOTROS.replace('#', '')} />
        <AboutUs />
        <Element name={LINK_NUESTRAS_LINEAS.replace('#', '')} />
        <ProductLines />
        <Element name={LINK_HUERTICALES.replace('#', '')} />
        <Huerticales />
        <Element name={LINK_EXPERIENCIAS.replace('#', '')} />
        <Experiencias />
        <Element name={LINK_CONTACTO.replace('#', '')} />
        <ContactInfo />
        <ContactForm />
        <Footer />
      </div>
    );
  }
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

import React from 'react';
import styled from 'styled-components';
import Input from './input';


const STATUS_IDLE = 'idle';
const STATUS_SENDING = 'sending';
const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';

const submitButtonText = {};
submitButtonText[STATUS_IDLE] = 'Enviar';
submitButtonText[STATUS_SENDING] = 'Enviando';
submitButtonText[STATUS_SUCCESS] = '¡Listo!';
submitButtonText[STATUS_ERROR] = '¡Ups!';

const resultText = {};
resultText[STATUS_SUCCESS] = 'Gracias por tu mensaje. Nos pondremos en contacto contigo en poco tiempo.';
resultText[STATUS_ERROR] = 'Por favor verifica tu conexión e intenta de nuevo.';

// #region Styles
const Section = styled.section`
  position: relative;
  padding: 1px 30px 4em;

  &:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    left: -50%;
    top: 0;
    z-index: -1;
    background: var(--base-color);
  }

  h1 {
    margin: 2em 0 1em;
    line-height: 0.8;
    color: var(--accent-color);
    font-family: 'todayshop', sans-serif;

    &:before {
      top: -24px;
    }
  }

  --label-color: var(--base-color);
  --label-background: white;
  --label-margin-bottom: 0.2em;
  --label-font-size: 1em;

  input[type="submit"] {
    display: block;
    margin: 1em auto 0;
    padding: 0.4em 2em;
    border: none;
    border-radius: 6px;
    font-family: 'todayshop-bold', sans-serif;
    color: white;
    background: var(--accent-color);
  }

  form {
    position: relative;
  }

  .result {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    color: white;
    pointer-events: none;
  }

  form label,
  form .result{
    transition: opacity 0.3s ease-in-out;
  }

  &.success,
  &.error {
    form label { opacity: 0; }
    .result { opacity: 1; }
  }

  @media screen and (min-width: 768px) {
    .mr-1 {
      padding-right: 0.6em;
    }
  }
`;
// #endregion

export default class ContactForm extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    status: STATUS_IDLE,
  };

  onInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  onSubmit = (event) => {
    event.preventDefault();

    const {
      name,
      email,
      phone,
      message,
    } = this.state;

    this.setState({
      status: STATUS_SENDING,
    });

    window.fetch('/contact', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    }).then(() => {
      this.setState({
        status: STATUS_SUCCESS,
      });
    });
  }

  render() {
    const { status } = this.state;

    return (
      <Section className={`container ${status}`}>
        <div className="row justify-content-center">
          <div className="col-md-10 col-xl-8">
            <h1>Envíanos <br className="d-md-none" />un mensaje</h1>
            <form onSubmit={this.onSubmit}>
              <div className="row no-gutters">
                <div className="col-md-4 mr-1">
                  <Input required label="Nombre" name="name" type="text" autocomplete="name" onInputChange={this.onInputChange} />
                </div>
                <div className="col-md-4 mr-1">
                  <Input required label="Email" name="email" type="email" autocomplete="email" onInputChange={this.onInputChange} />
                </div>
                <div className="col-md-4">
                  <Input required label="Teléfono" name="phone" type="tel" autocomplete="tel" onInputChange={this.onInputChange} />
                </div>
              </div>
              <Input required label="Mensaje" name="message" type="textarea" onInputChange={this.onInputChange} />
              <input type="submit" value={submitButtonText[status]} />
              <div className="result">
                <p>{resultText[status]}</p>
              </div>
            </form>
          </div>
        </div>
      </Section>
    );
  }
}

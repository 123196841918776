import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const StyledInput = styled.div`
  position: relative;

  --height-for-input: calc(var(--label-font-size) * 2.4);
  --height-for-textarea: calc(var(--label-font-size) * 7);

  label {
    position: relative;
    width: 100%;
    height: ${({ isTextarea }) => (isTextarea ? 'var(--height-for-textarea)' : 'var(--height-for-input)')};
    margin-bottom: var(--label-margin-bottom);
    font-size: var(--label-font-size);
    color: var(--label-color);
    border-radius: var(--label-border-radius);
    background: var(--label-background);

    span {
      position: absolute;
      left: 0.7em;
      line-height: 2.4;
      transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
      transform-origin: left center;
    }

    &.raised span,
    input:focus + span,
    textarea:focus + span {
      opacity: 0.4;
      transform: scale3d(0.7, 0.7, 0.7) translate3d(-0.3em, calc(var(--label-font-size) * -0.8), 0);
    }

    input,
    textarea {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 1.3;
      bottom: 0;
      padding: 0.8em 0.5em 0;
      background: transparent;
      border: none;
      outline: none;
    }
  }
`;

class Input extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    onInputChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: '',
    required: false,
    focusOnMount: false,
  }

  constructor(props) {
    super(props);

    const { value } = props;

    this.state = { value };
  }

  componentDidMount() {
    const { focusOnMount } = this.props;
    if (focusOnMount) this.node.focus();
  }

  onInputChange = (event) => {
    const { target: { value } } = event;
    const { onInputChange } = this.props;

    this.setState({ value });
    onInputChange(event);
  }

  render() {
    const {
      label,
      name,
      type,
      required,
    } = this.props;
    const { value } = this.state;
    const id = `${name}Input`;
    const isTextarea = type === 'textarea';

    return (
      <StyledInput isTextarea={isTextarea}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={id} className={value.length ? 'raised' : ''}>
          {isTextarea && (
            <textarea
              id={id}
              name={name}
              value={value}
              required={required}
              onChange={this.onInputChange}
              ref={(node) => { this.node = node; }}
            />
          )}
          {!isTextarea && (
            <input
              id={id}
              name={name}
              type={type}
              value={value}
              required={required}
              onChange={this.onInputChange}
              ref={(node) => { this.node = node; }}
            />
          )}
          <span>{label}</span>
        </label>
      </StyledInput>
    );
  }
}


export default Input;

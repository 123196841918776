import React from 'react';
import styled from 'styled-components';
import Banner from './banner';


// #region Styles
const Section = styled.section`
  position: relative;
  padding: 1px 0;

  --header-height-xs: 0;

  h1 {
    margin-bottom: 1em;
  }

  .row img {
    width: 100%;
    padding: 6px;
  }

  @media screen and (min-width: 1200px) {
    padding: 1em 15px;
  }
`;
// #endregion

export default class AboutUs extends React.Component {
  render() {
    return (
      <Section className="container">
        <h1>Experiencias</h1>
        <div className="d-md-none">
          <Banner slidesData={[{
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-10@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-1@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-2@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-3@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-4@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-5@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-6@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-7@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-8@2x.jpg',
          }, {
            alt: 'Huerto comunitario terraza',
            srcXs: 'img/experiencias-9@2x.jpg',
          }]}
          />
        </div>
        <div className="row no-gutters justify-content-center d-none d-md-flex">
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-1@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-2@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-3@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-4@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-5@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl d-xl-none">
            <img src="img/experiencias-6@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="d-none d-lg-block col-lg-3 col-xl d-xl-none">
            <img src="img/experiencias-7@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="d-none d-lg-block col-lg-3 col-xl d-xl-none">
            <img src="img/experiencias-8@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
        </div>
        <div className="row no-gutters justify-content-center d-none d-md-flex">
          <div className="d-none d-xl-block col-xl">
            <img src="img/experiencias-6@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 d-lg-none d-xl-block col-xl">
            <img src="img/experiencias-7@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 d-lg-none d-xl-block col-xl">
            <img src="img/experiencias-8@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-9@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
          <div className="col-md-4 col-lg-3 col-xl">
            <img src="img/experiencias-10@2x.jpg" alt="Huerto comunitario terraza" />
          </div>
        </div>
      </Section>
    );
  }
}

import React from 'react';
import styled from 'styled-components';


// #region Styles
const Section = styled.section`
  position: relative;

  h1 {
    margin-bottom: 1.4em;
  }

  @media screen and (min-width:768px ) {
    padding: 1px 0 4em;

    .categories {
      text-align: center;

      span {
        display: inline-block;
        margin-bottom: 4em;
        padding: 0.4em 1em 0.2em;
        font-family: 'todayshop-medium';
        line-height: 1em;
        letter-spacing: 0.6px;
        border-radius: 0.4em;
        border: 1px solid white;
        cursor: pointer;

        &.active {
          color: var(--accent-color);
          border: 1px solid var(--accent-color);
          pointer-events: none;
        }
      }
    }
  }
`;

const Slider = styled.div`
  position: relative;
  overflow: hidden;

  .filler {
    opacity: 0;
  }
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    left: 50%;
    margin-left: -360px;
  }

  @media screen and (min-width: 992px) {
    margin-left: -470px;
  }

  @media screen and (min-width: 1200px) {
    margin-left: -570px;
  }
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: transform 0.6s ease-in-out,
  opacity 0.6s ease-in-out;
  transform: translateX(6%);

  &:first-child {
    transform: translateX(-4%);
  }

  &:nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-family: 'todayshop-light', sans-serif;
    font-size: 2.4em;

    span {
      display: block;
      margin-top: -0.6em;
      font-family: 'todayshop-bold', sans-serif;
    }
  }

  .filler & {
    position: relative;
    transform: translateX(0);
  }

  picture {
    position: relative;
    left: -15px;

    img {
      position: relative;
      width: calc(106% + 30px);
      left: -3%;
    }
  }

  p {
    margin: 1.6em 15px 3em;

    span {
      margin: 0 0.6em;
    }
  }

  @media screen and (min-width: 768px) {
    .center-md {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2em 0 4em;

      p {
        margin: 0;
      }
    }

    .padding-md {
      padding: 0 5.4em 0 1.6em;
    }
  }

  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 4.6em;
    }

    .padding-md {
      padding: 0 4em;
    }

    p span {
      margin: 0 1em;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  width: 3em;
  height: 4em;
  top: 0.6em;
  cursor: pointer;
  border: none;
  outline: none !important;
  background: none;
  z-index: 1;

  &:before,
  &:after,
  span {
    content: "";
    position: absolute;
    width: 18px;
    height: 4px;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -12px;
    border-radius: 2px;
    transform-origin: right center;
    transform: rotate(45deg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background: var(--accent-color);
  }

  span {
    box-shadow: none;
    z-index: 1;
  }

  &:after {
    transform: rotate(-45deg);
    margin-top: -3px;
  }

  &.arrow-left {
    transform: rotate(180deg);
  }

  &.arrow-right {
    right: 0;
  }

  @media screen and (min-width: 768px) {
    top: 50%;
    margin-top: -2em;

    &.arrow-left {
      left: 1em;
    }

    &.arrow-right {
      right: 1em;
    }
  }

  @media screen and (min-width: 1200px) {
    &.arrow-left {
      left: calc((100% - (1140px + 4em)) / 2);
    }

    &.arrow-right {
      right: calc((100% - (1140px + 4em)) / 2);
    }
  }
`;
// #endregion

export default class Banner extends React.Component {
  lastClick = 0;

  maxHeight = 0;

  currentSlide = 1;

  state = {
    cats: ['active', '', '', ''],
  }

  fixHeight = () => {
    const slides = this.screenNode.children;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slides.length; i++) {
      const { clientHeight } = slides[i];
      if (clientHeight > this.maxHeight) {
        this.sliderNode.style.height = `${clientHeight}px`;
        this.maxHeight = clientHeight;
      }
    }
  }

  nextSlide = () => {
    const firstSlide = this.screenNode.firstChild;
    this.screenNode.appendChild(firstSlide);
    this.fixHeight();
    this.currentSlide += 1;
    this.setState(prevState => ({
      cats: [
        prevState.cats[3],
        prevState.cats[0],
        prevState.cats[1],
        prevState.cats[2],
      ],
    }));
  }

  previousSlide = () => {
    const firstSlide = this.screenNode.firstChild;
    const lastSlide = this.screenNode.lastChild;
    this.screenNode.insertBefore(lastSlide, firstSlide);
    this.fixHeight();
    this.currentSlide -= 1;
    this.setState(prevState => ({
      cats: [
        prevState.cats[1],
        prevState.cats[2],
        prevState.cats[3],
        prevState.cats[0],
      ],
    }));
  }

  onNextClick = () => {
    if (Date.now() - this.lastClick < 650) return;
    this.lastClick = Date.now();
    this.nextSlide();
  }

  onPrevClick = () => {
    if (Date.now() - this.lastClick < 650) return;
    this.lastClick = Date.now();
    this.previousSlide();
  }

  goToSlide = (event) => {
    const slideToShow = event.currentTarget.dataset.slide;

    let stepsToSlide = slideToShow - this.currentSlide;

    if (stepsToSlide > 0) {
      while (stepsToSlide > 0) {
        this.nextSlide();
        stepsToSlide -= 1;
      }
    } else if (stepsToSlide < 0) {
      while (stepsToSlide < 0) {
        this.previousSlide();
        stepsToSlide += 1;
      }
    }
  }

  render() {
    const { cats } = this.state;

    return (
      <Section>
        <h1>Nuestras líneas</h1>
        <div className="categories d-none d-md-block">
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          <span className={cats[0]} onClick={this.goToSlide} data-slide="1">HOGAR</span>
          <span className={cats[1]} onClick={this.goToSlide} data-slide="2">COMUNITARIA</span>
          <span className={cats[2]} onClick={this.goToSlide} data-slide="3">CORPORATIVA</span>
          <span className={cats[3]} onClick={this.goToSlide} data-slide="4">INSUMOS</span>
        </div>
        <Slider ref={(node) => { this.sliderNode = node; }}>
          <Screen ref={(node) => { this.screenNode = node; }} className="container">
            <Slide className="row no-gutters">
              <div className="col-md-6 col-lg-5 offset-lg-1 center-md">
                <h2>Línea<span>insumos</span></h2>
                <p className="d-none d-md-block">
                  Para el correcto mantenimiento del Huertical es necesario
                  contar con los siguientes insumos que puede adquirir por medio
                  de nosotros:<br />
                  <br />
                  <span>- Plántulas</span> <span>- Repelente</span> <span>- Nutrientes</span>
                </p>
              </div>
              <div className="col-md-6 col-lg-5 padding-md">
                <picture>
                  <source media="(min-width: 1200px)" srcSet="" />
                  <source media="(min-width: 768px)" srcSet="" />
                  <img
                    src="img/linea-insumos-xs@2x.jpg"
                    alt="Línea insumos"
                    onLoad={this.fixHeight}
                  />
                </picture>
              </div>
              <div className="col d-md-none">
                <p>
                  Para el correcto mantenimiento del Huertical es necesario
                  contar con los siguientes insumos que puede adquirir por medio
                  de nosotros:<br />
                  <br />
                  <span>- Plántulas</span> <span>- Repelente</span> <span>- Nutrientes</span>
                </p>
              </div>
            </Slide>
            <Slide className="row no-gutters">
              <div className="col-md-6 col-lg-5 offset-lg-1 center-md">
                <h2>Línea<span>hogar</span></h2>
                <p className="d-none d-md-block">
                  Ofrecemos una solución a la producción de
                  alimentos frescos, con tecnología sostenible,
                  de manera eficiente, a bajo costo y divertida.
                </p>
              </div>
              <div className="col-md-6 col-lg-5 padding-md">
                <picture>
                  <source media="(min-width: 1200px)" srcSet="" />
                  <source media="(min-width: 768px)" srcSet="" />
                  <img
                    src="img/linea-hogar-xs@2x.jpg"
                    alt="Línea hogar"
                    onLoad={this.fixHeight}
                  />
                </picture>
              </div>
              <div className="col d-md-none">
                <p>
                  Ofrecemos una solución a la producción de
                  alimentos frescos, con tecnología sostenible,
                  de manera eficiente, a bajo costo y divertida.
                </p>
              </div>
            </Slide>
            <Slide className="row no-gutters">
              <div className="col-md-6 col-lg-5 offset-lg-1 center-md">
                <h2>Línea<span>comunitaria</span></h2>
                <p className="d-none d-md-block">
                  Permite implementar sistemas hidropónicos y convencionales de
                  producción de hortalizas, mediante la participación activa de
                  la comunidad.
                </p>
              </div>
              <div className="col-md-6 col-lg-5 padding-md">
                <picture>
                  <source media="(min-width: 1200px)" srcSet="" />
                  <source media="(min-width: 768px)" srcSet="" />
                  <img
                    src="img/linea-comunitaria-xs@2x.jpg"
                    alt="Línea comunitaria"
                    onLoad={this.fixHeight}
                  />
                </picture>
              </div>
              <div className="col d-md-none">
                <p>
                  Permite implementar sistemas hidropónicos y convencionales de
                  producción de hortalizas, mediante la participación activa de
                  la comunidad.
                </p>
              </div>
            </Slide>
            <Slide className="row no-gutters">
              <div className="col-md-6 col-lg-5 offset-lg-1 center-md">
                <h2>Línea<span>corporativa</span></h2>
                <p className="d-none d-md-block">
                  Apoyamos el logro de los objetivos de diferentes instituciones
                  que se quieren vincular con el desarrollo de proyectos productivos.<br />
                  <br />
                  - Proyectos de desarrollo de vivienda.<br />
                  - Instituciones educativas.<br />
                  - Líderes comunitarios.
                </p>
              </div>
              <div className="col-md-6 col-lg-5 padding-md">
                <picture>
                  <source media="(min-width: 1200px)" srcSet="" />
                  <source media="(min-width: 768px)" srcSet="" />
                  <img
                    src="img/linea-corporativa-xs@2x.jpg"
                    alt="Línea corporativa"
                    onLoad={this.fixHeight}
                  />
                </picture>
              </div>
              <div className="col d-md-none">
                <p>
                  Apoyamos el logro de los objetivos de diferentes instituciones
                  que se quieren vincular con el desarrollo de proyectos productivos.<br />
                  <br />
                  - Proyectos de desarrollo de vivienda.<br />
                  - Instituciones educativas.<br />
                  - Líderes comunitarios.
                </p>
              </div>
            </Slide>
          </Screen>
          <Arrow type="button" className="arrow-left" onClick={this.onPrevClick}><span /></Arrow>
          <Arrow type="button" className="arrow-right" onClick={this.onNextClick}><span /></Arrow>
        </Slider>
      </Section>
    );
  }
}

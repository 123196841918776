import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  LINK_HOME,
  LINK_NOSOTROS,
  LINK_NUESTRAS_LINEAS,
  LINK_HUERTICALES,
  LINK_EXPERIENCIAS,
  LINK_CONTACTO,
} from './constants';


// #region StyledHeader
const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  height: var(--header-height-xs);
  top: 0;
  z-index: 10;
  background: white;
  box-shadow: 0 0 0.6em rgba(0, 0, 0, 0.2);

  .main-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: var(--header-height-xs);
    top: 0;
    background: white;
  }

  img {
    display: block;
    height: 48px;
    margin-left: 1em;
  }

  nav {
    position: absolute;
    width: 100%;
    max-height: calc(100vh - var(--header-height-xs));
    left: 0;
    top: 100%;
    padding: 3em 0 2em;
    z-index: -1;
    background: var(--gray-color);
    overflow: auto;
    box-shadow: 0 0 0.6em rgba(0, 0, 0, 0.2);
    transform: translateY(-102%);
    transition: transform 0.3s ease-in-out;

    &.shown {
      transform: translateY(0%);
      transition: transform 0.2s ease-in-out;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    padding: 0;
  }

  li {
    position: relative;
    width: 70%;
    margin: 0;
    padding: 0.3em 0 0.3em;
    font-size: 1.3em;
    text-align: center;

    &:not(:last-child) {
      border-bottom: 1px solid var(--base-color);
    }

    a, button {
      display: inline-block;
      width: 100%;
      text-decoration: none;
      color: var(--base-color);
      font-weight: lighter;
    }
  }

  .active {
    color: red;
  }

  .buttons {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    width: 100%;
    left: 0;
    pointer-events: none;

    .icon-menu {
      position: relative;
      padding: 40px;
      border: none;
      outline: none;
      background: none;
      pointer-events: all;

      div {
        position: absolute;
        width: 22px;
        height: 2px;
        top: 48%;
        left: 50%;
        margin-left: -11px;
        background: var(--base-color);
        transition: transform 0.2s ease-in-out;

        &:after,
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: -6px;
          left: 0;
          background: var(--base-color);
          transform-origin: right;
          transition: transform 0.2s ease-in-out;
        }

        &:after {
          top: 6px;
        }
      }

      &.open div {
        transform: rotate(-90deg);

        &:before {
          transform: translateY(6px) rotate(40deg) scaleX(0.7)
        }

        &:after {
          transform: translateY(-6px) rotate(-40deg) scaleX(0.7)
        }
      }
    }
  }

  @media (min-width: 768px) {
    height: var(--header-height-md);

    .main-header {
      justify-content: space-between;
      height: var(--header-height-md);
      margin: 0 auto;
      padding: 0 2.6em;
    }

    img {
      height: 42.8px;
      margin: 0;
    }

    nav {
      max-height: calc(100vh - var(--header-height-md));
    }
  }

  @media (min-width: 992px) {
    height: calc(var(--header-height-md) + var(--contact-bar-height));

    img {
      height: 34px;
    }

    nav {
      position: relative;
      padding: 0;
      top: 0;
      z-index: 0;
      box-shadow: none;
      transform: translateX(0);
      background: none;

      ul {
        flex-direction: row;
        justify-content: flex-end;
        margin: 0;

        li {
          width: initial;
          margin: 0 2em;
          font-size: 0.9em;
          border-bottom: none !important;
        }

        .logo-space {
          width: 16em;
        }
      }
    }

    a:not(.no-underline).active,
    a:not(.no-underline):hover {
      position: relative;
      color: var(--base-color);

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.2em;
        bottom: -0.2em;
        left: 0;
        background: var(--base-color);
      }
    }

    .contact-info {
      height: var(--contact-bar-height);
      background: var(--base-color);

      a {
        display: inline-block;
        position: relative;
        padding-left: 2em;
        margin-left: 2em;
        color: white;
        line-height: 2;
        font-family: 'todayshop-light', sans-serif;

        &:active,
        &:hover {
          color: white;
        }
      }

      .icon-phone,
      .icon-mail {
        position: absolute;
        width: 1em;
        height: 1.8em;
        left: 0.7em;
        background-image: url(img/icon-phone.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .icon-mail {
        background-image: url(img/icon-email.svg);
      }
    }
  }

  @media (min-width: 1200px) {
    height: calc(var(--header-height-xl) + (var(--contact-bar-height) * 1.2));

    .contact-info {
      height: calc(var(--contact-bar-height) * 1.2);
      line-height: 2.4;
    }

    .main-header {
      height: var(--header-height-xl);
    }

    img {
      height: 48px;
    }

    ul {
      display: flex;
      align-items: center;
      position: initial;
    }

    li {
      text-align: center;

      a {
        font-size: 1em;
      }
    }
  }
`;
// #endregion

export default class Header extends React.Component {
  static propTypes = {
    scrollTo: PropTypes.func.isRequired,
  }

  state = {
    menuOpen: false,
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }));
  }

  onLinkClick = () => {
    const { menuOpen } = this.state;
    const { scrollTo } = this.props;
    if (menuOpen) this.toggleMenu();
    setTimeout(scrollTo, 10);
  }

  render() {
    const { menuOpen } = this.state;

    return (
      <StyledHeader>
        <div className="contact-info d-none d-lg-block">
          <a href="tel:+573108502341">
            <i className="icon-phone" />
            Contacto: (57) 310 850 2341
          </a>
          <a href="mailto:info@huertical.com">
            <i className="icon-mail" />
            Email: info@huertical.com
          </a>
        </div>
        <div className="main-header" onMouseLeave={this.hideMenus}>
          <a href={LINK_HOME} onClick={this.toggleMenu} className="no-underline">
            <img src="img/logo-huertical.svg" alt="Huertical - Cultivando en casa" />
          </a>
          <nav className={menuOpen ? 'shown' : ''}>
            <ul>
              <li className="d-lg-none">
                <a href={LINK_HOME} onClick={this.onLinkClick}>INICIO</a>
              </li>
              <li>
                <a href={LINK_NOSOTROS} onClick={this.onLinkClick}>NOSOTROS</a>
              </li>
              <li>
                <a href={LINK_NUESTRAS_LINEAS} onClick={this.onLinkClick}>NUESTRAS LÍNEAS</a>
              </li>
              <li>
                <a href={LINK_HUERTICALES} onClick={this.onLinkClick}>HUERTICALES</a>
              </li>
              <li>
                <a href={LINK_EXPERIENCIAS} onClick={this.onLinkClick}>EXPERIENCIAS</a>
              </li>
              <li>
                <a href={LINK_CONTACTO} onClick={this.onLinkClick}>CONTACTO</a>
              </li>
            </ul>
          </nav>
          <div className="buttons">
            <button type="button" className={`icon-menu d-lg-none ${menuOpen ? 'open' : ''}`} onClick={this.toggleMenu}>
              <div />
            </button>
          </div>
        </div>
      </StyledHeader>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const INTERVAL_PER_SLIDE = 3000;

// #region Styles
const Slider = styled.section`
  position: relative;
  margin-top: var(--header-height-xs);
  overflow: hidden;

  .filler {
    opacity: 0;
  }

  img {
    width: 106%;
    left: -3%;
  }

  @media screen and (min-width: 768px) {
    margin-top: var(--header-height-md);
  }

  @media screen and (min-width: 1200px) {
    margin-top: var(--header-height-xl);
  }
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  picture {
    position: absolute;
    top: 0;
    left: -5%;
    opacity: 0;
    transition: transform 0.6s ease-in-out,
    opacity 0.6s ease-in-out;

    transform: translateX(6%);

    &:first-child {
      transform: translateX(-4%);
    }

    &:nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  width: 3em;
  height: 4em;
  top: 50%;
  margin-top: -2em;
  cursor: pointer;
  border: none;
  outline: none !important;
  background: none;
  z-index: 1;

  &:before,
  &:after,
  span {
    content: "";
    position: absolute;
    width: 18px;
    height: 4px;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -12px;
    background: rgba(255,255,255,1);
    border-radius: 2px;
    transform-origin: right center;
    transform: rotate(45deg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  span {
    box-shadow: none;
    z-index: 1;
  }

  &:after {
    transform: rotate(-45deg);
    margin-top: -3px;
  }

  &.arrow-left {
    transform: rotate(180deg);
  }

  &.arrow-right {
    right: 0;
  }
`;

const Bullets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 3em;
  bottom: 0;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 100%;
    background: #AEB7BC;

    &.active {
      background: #ffffff;
    }
  }
`;
// #endregion

export default class Banner extends React.Component {
  static propTypes = {
    slidesData: PropTypes.arrayOf(PropTypes.shape({
      alt: PropTypes.string,
      srcXs: PropTypes.string,
      srcMd: PropTypes.string,
      srcXl: PropTypes.string,
    })).isRequired,
  }

  lastClick = 0;

  timer = 0;

  componentDidMount() {
    this.timer = setInterval(this.nextSlide, INTERVAL_PER_SLIDE);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  nextSlide = () => {
    const firstSlide = this.screenNode.firstChild;
    const firstBullet = this.bulletsNode.firstChild;
    const lastBullet = this.bulletsNode.lastChild;
    this.screenNode.appendChild(firstSlide);
    this.bulletsNode.insertBefore(lastBullet, firstBullet);
  }

  previousSlide = () => {
    const firstBullet = this.bulletsNode.firstChild;
    const firstSlide = this.screenNode.firstChild;
    const lastSlide = this.screenNode.lastChild;
    this.bulletsNode.appendChild(firstBullet);
    this.screenNode.insertBefore(lastSlide, firstSlide);
  }

  onNextClick = () => {
    if (Date.now() - this.lastClick < 650) return;
    this.lastClick = Date.now();
    clearInterval(this.timer);
    this.nextSlide();
    this.timer = setInterval(this.nextSlide, INTERVAL_PER_SLIDE);
  }

  onPrevClick = () => {
    if (Date.now() - this.lastClick < 650) return;
    this.lastClick = Date.now();
    clearInterval(this.timer);
    this.previousSlide();
    this.timer = setInterval(this.nextSlide, INTERVAL_PER_SLIDE);
  }

  render() {
    const { slidesData } = this.props;

    const slides = [];
    const bullets = [];

    slidesData.map((slideData, index) => {
      slides.push(
        // eslint-disable-next-line react/no-array-index-key
        <picture key={index}>
          <source media="(min-width: 1200px)" srcSet={slideData.srcXl} />
          <source media="(min-width: 768px)" srcSet={slideData.srcMd} />
          <img src={slideData.srcXs} alt={slideData.alt} />
        </picture>,
      );

      return bullets.push(
        // eslint-disable-next-line react/no-array-index-key
        <span className={index === 0 ? 'active' : ''} key={index} />,
      );
    });

    return (
      <Slider>
        <div className="filler">
          {slides[0]}
        </div>
        <Screen ref={(node) => { this.screenNode = node; }}>
          {slides}
        </Screen>
        <Arrow type="button" className="arrow-left" onClick={this.onPrevClick}><span /></Arrow>
        <Arrow type="button" className="arrow-right" onClick={this.onNextClick}><span /></Arrow>
        <Bullets ref={(node) => { this.bulletsNode = node; }}>
          {bullets}
        </Bullets>
      </Slider>
    );
  }
}

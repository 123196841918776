import React from 'react';
import styled from 'styled-components';


// #region Styles
const Section = styled.section`
  position: relative;
  padding-top: 1px;
  padding-bottom: 6em;

  h1 {
    margin: 3em 0 0;
  }

  h2 {
    margin-bottom: 0;
    line-height: 1;
    text-align: center;
    font-family: 'todayshop-medium', sans-serif;
  }

  a {
    color: #999999;
  }

  p {
    text-align: center;
  }

  .icon {
    margin: 3em auto 1em;
    width: 75px;
    height: 75px;
    border-radius: 0.4em;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--base-color);

    &.phone {
      background-size: 33px;
      background-image: url(img/icon-phone.svg);
    }

    &.email {
      background-size: 40px;
      background-image: url(img/icon-email.svg);
    }

    &.address {
      background-size: 25px;
      background-image: url(img/icon-address.svg);
    }
  }

  @media screen and (min-width: 1200px) {
    h1 {
      margin: 2em 0 1em;
    }
  }
`;
// #endregion

export default class Footer extends React.Component {
  render() {
    return (
      <Section className="container">
        <h1>Contacto</h1>
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="icon phone" />
            <h2>Celular</h2>
            <p>
              <a href="tel:+573102118689">+57 310 211 8689</a><br />
              <a href="tel:+573203453489">+57 320 345 3489</a>
            </p>
          </div>
          <div className="col-md-4">
            <div className="icon address" />
            <h2>Dirección</h2>
            <p>
              Carrera 7 # 246 - 96 Ofi. 5
              <br />
              Bogotá - Colombia
            </p>
          </div>
          <div className="col-md-4">
            <div className="icon email" />
            <h2>Email</h2>
            <p>
              <a href="mailto:info@huertical.com">info@huertical.com</a>
            </p>
          </div>
        </div>
      </Section>
    );
  }
}

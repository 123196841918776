import React from 'react';
import styled from 'styled-components';


// #region Styles
const Section = styled.section`
  position: relative;
  padding: 1px 30px;

  h1 {
    margin-bottom: 0;
  }

  .row {
    padding-bottom: 6em;
  }

  p {
    margin: 1.6em auto 0;
  }

  blockquote {
    position: relative;
    padding: 2.4em 0 2em 3.4em;
    font-family: 'todayshop-bold', sans-serif;
    font-size: 1.8em;
    line-height: 0.9em;
    text-align: center;
    color: var(--accent-color);

    &:before {
      content: "";
      position: absolute;
      width: 120%;
      height: 100%;
      left: -10%;
      top: 0;
      background-image: url(img/somos-bg-xs@2x.jpg);
      background-size: cover;
      background-position: left center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  @media screen and (min-width: 768px) {
    blockquote:before {
      background-image: url(img/somos-bg-md.jpg);
    }
  }

  @media screen and (min-width: 992px) {
    blockquote {
      padding-left: 35%;
      font-size: 3em;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 1em 15px;

    blockquote{
      font-size: 3.4em;
      padding: 2.6em 0 2.6em 40%;

      &:before {
        width: 130%;
        left: -15%;
        background-image: url(img/somos-bg-md@2x.jpg);
      }
    }
  }
`;

const SubSection = styled.div`
  h2 {
    text-align: center;
    font-family: 'todayshop-medium', sans-serif;
    letter-spacing: 0.5px;
    line-height: 1em;
    color: var(--text-color);
  }

  .icon {
    margin: 4em auto 1.5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.somos,
    &.diseno,
    &.mantenimiento {
      width: calc(90px * 1);
      height: calc(90px * 1);
      background-image: url(img/icon-somos@2x.png);
    }

    &.diseno {
      background-image: url(img/icon-diseno@2x.png);
    }

    &.mantenimiento {
      background-image: url(img/icon-mantenimiento@2x.png);
    }
  }

  @media screen and (min-width: 992px) {
    .icon {
      &.merchandising,
      &.estampacion {
        margin-top: 3em;
      }
    }
  }
`;
// #endregion

export default class AboutUs extends React.Component {
  render() {
    return (
      <Section className="container">
        <h1>Nosotros</h1>
        <div className="row align-items-start">
          <SubSection className="col-md-4">
            <div className="icon somos" />
            <h2>¿Quiénes <br />somos?</h2>
            <p>
              Somos un equipo <br className="d-md-none" />
              interdisciplinario apasionado por <br className="d-md-none" />
              la producción de alimentos.
            </p>
          </SubSection>
          <SubSection className="col-md-4">
            <div className="icon diseno" />
            <h2>Diseño e instalación <br />de huertas</h2>
            <p>
              Diseñamos un plan completo para <br className="d-md-none" />
              cultivar hortalizas que se ajusten a <br className="d-md-none" />
              tus hábitos alimenticios o <br className="d-md-none" />
              tendencia del mercado.
            </p>
          </SubSection>
          <SubSection className="col-md-4">
            <div className="icon mantenimiento" />
            <h2>Mantenimiento <br />de huertas</h2>
            <p>
              Ofrecemos dos clases de <br />
              mantenimientos: Básico o Completo.<br className="d-md-none" />
              Los mantenimientos garantizan el <br className="d-md-none" />
              correcto funcionamiento de la huerta y <br className="d-md-none" />
              una cosecha sana y de buena calidad.
            </p>
          </SubSection>
        </div>
        <blockquote>
          Huertical es <br />
          innovador, <br className="d-lg-none" />
          limpio, <br />
          ambientalmente <br />
          amigable, <br className="d-lg-none" />
          sustentable <br className="d-none d-lg-inline" /> y <br className="d-lg-none" />
          eficiente en el <br className="d-lg-none" />
          uso <br className="d-none d-lg-inline" /> del agua y <br className="d-lg-none" />
          de la <br className="d-none d-lg-inline" /> energía.
        </blockquote>
      </Section>
    );
  }
}
